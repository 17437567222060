<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title
      id="dashboard_ped_title"
      class="px-5 pb-0 text-uppercase v-title-cadastro"
    >
      RESUMO DE P&D {{ selectedUnit && ` — ${selectedUnit}` }}
    </v-card-title>
    <v-card-text>
      <div class="d-flex pt-0">
        <v-spacer></v-spacer>
        <div class="table-v-action-button mr-3 pt-1" :aria-label="'atualizarButton'" @click="doLoad()">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <v-menu offset-y dense max-width="160px">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="table-v-action-button mr-3 pt-1"
              :aria-label="'exportacaoButton'"
            >
              <v-icon>mdi-file-export-outline</v-icon> Exportação
            </div>
          </template>
          <v-list>
            <v-list-item style="cursor: pointer" @click="handleExportCsv()" :aria-label="'exportarCsvButton'">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-delimited-outline</v-icon> Exportar CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer" @click="handleExportXlsx()" :aria-label="'exportarXslxButton'">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-excel-outline</v-icon> Exportar XLSX
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer" @click="handleExportPdf()">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-chart-outline</v-icon> Exportar PDF
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y v-if="!isClient">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="table-v-action-button mr-3 pt-1"
              v-bind="attrs"
              v-on="on"
              :aria-label="'opcoesDeCalculo'"
            >
              <v-icon>mdi-cog-sync</v-icon>
              Opções de Cálculo
            </div>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                class="table-v-action-button"
                :aria-label="'recalcularItem'"
                @click="recalcularDialog = true"
              >
                <v-icon>mdi-cog-sync</v-icon>Recalcular
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                class="table-v-action-button"
                :aria-label="'finalizarCalculoItem'"
                @click="finalizarDialog = true"
              >
                <v-icon>mdi-archive-cog</v-icon>Finalizar Cálculo
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <monthly-filter
          class="mb-n1 mt-0 mr-3"
          style="width: 260px"
          :aria-label="'selecionarPeriodoCalendar'"
          @change="debounceDoLoad({ competencia: $event })"
        ></monthly-filter>
        <servico-select
          class="mb-n1 mt-0 mr-3"
          style="max-width: 260px"
          :aria-label="'beneficioSelect'"
          @change="debounceDoLoad({ servico: $event })"
        ></servico-select>
        <unidade-select
          v-model="unidade"
          class="mb-n1 mt-0 mr-3"
          style="max-width: 280px"
          :aria-label="'unidadeSelect'"
          @change="debounceDoLoad()"
          @unidades="unidadeList = $event"
        ></unidade-select>
      </div>
    </v-card-text>
    <v-data-table
      data-app
      fixed-header
      :footer-props="{ 'items-per-page-options': [-1] }"
      :headers="dataTableHeaders"
      hide-default-footer
      hide-default-header
      id="dashboard_ped_table"
      :items="relatorioFormatado"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="(head, k) in headers"
              :class="[`text-${head.align || 'start'}`, head.class]"
              :key="k"
              role="columnheader"
              scope="col"
              :style="{ minWidth: head.width }"
            >
              <template v-if="!isClient">
                <div v-if="head.loading" class="loading-icon-wrapper">
                  <v-icon
                    class="animated-loading-icon"
                    dense
                    small
                  >
                    mdi-loading
                  </v-icon>
                </div>
                <v-btn
                  v-else-if="mostrarIcone(head)"
                  icon
                  :title="`${
                    head.locked ? 'Permitir' : 'Ocultar da'
                  } visão do cliente`"
                  @click="changeVisibility(head)"
                >
                  <v-icon small dense>
                    {{
                      head.locked ? "mdi-eye-off-outline" : "mdi-eye-outline"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ head.text }}
              </span>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item, headers }">
        <tr
          class="table-v-tr"
          style="cursor: default"
          :class="{
            'font-weight-bold blue lighten-5': item.isTotal,
            'highlight': item.highlight,
          }"
        >
          <td
            v-for="col in headers"
            v-bind:key="col.value"
            :class="{
              nowrap: col.nowrap,
              'text-end': col.align == 'end',
              'font-weight-bold': item.isTotal,
            }"
            :style="{ 'min-width': col.minWidth, 'z-index': col.zIndex }"
            :title="getStatusTitle(col, item)"
          >
            <template v-if="showStatus(col, item)">
              {{ getStatusMessage(col, item) }}
            </template>
            <template v-else-if="showLock(col, item)">
              <span>
                {{ getFormattedValue(col, item) }}
              </span>
              <v-icon
                v-if="isLocked(col, item)"
                color="info"
                dense
              >
                mdi-lock-check
              </v-icon>
              <v-icon v-else color="green" dense>mdi-lock-open</v-icon>
            </template>
            <template v-else>{{ getFormattedValue(col, item) }}</template>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-card-text class="pt-0">
      <v-row id="dashboard_ped_chart">
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">
            DISPÊNDIO TOTAL ACUMULADO NO PERÍODO
          </div>
          <div style="background: white; border-radius: 5px; padding: 20px">
            <div style="height: 280px; max-width: 280px; margin: 0px auto">
              <pie-chart
                :chartdata="pieChart.chartdata"
                :options="pieChart.options"
              ></pie-chart>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">
            DISPÊNDIO TOTAL MENSAL
          </div>
          <div style="background: white; border-radius: 5px; padding: 20px">
            <div style="height: 280px; margin: 0px auto">
              <bar-chart
                style="height: 280px"
                :chartdata="barChart.chartdata"
                :options="barChart.options"
              ></bar-chart>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">
            Dispêndio MENSAL POR CATEGORIA
          </div>
          <div style="background: white; border-radius: 5px; padding: 20px">
            <div style="height: 280px; margin: 0px auto">
              <line-chart
                style="height: 280px"
                :chartdata="lineChart.chartdata"
                :options="lineChart.options"
              ></line-chart>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="recalcularDialog"
      scrollable
      persistent
      max-width="500"
      @keydown="dialogKeyDown($event)"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-cog-sync</v-icon>Recalcular
          <v-btn
            @click="recalcularDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p><b> Selecione o período para finalização:</b></p>
          <v-menu
            v-model="dateMenu1"
            :close-on-click="competenciaRecalculo.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaRecalculo | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaRecalculo"
              range
              type="month"
              @click:month="dateMenu1 = competenciaRecalculo.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="recalcularDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="recalcularPeriodo()"
          >
            Executar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="finalizarDialog"
      scrollable
      persistent
      max-width="600"
      @keydown="dialogKeyDown($event)"
    >
      <v-card>
        <v-card-title>
          <v-icon x-large class="mr-3">mdi-archive-cog</v-icon> Finalizar Cálculo
          <v-btn
            @click="finalizarDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p><b> Selecione o período para finalização:</b></p>
          <v-menu
            v-model="dateMenu2"
            :close-on-click="competenciaRecalculo.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaRecalculo | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaRecalculo"
              range
              type="month"
              @click:month="dateMenu2 = competenciaRecalculo.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
          <v-alert class="mt-2" dense type="warning">
            <b>Cuidado</b>, fechar o resumo irá fechar o cálculo de todos os módulos relevantes do Teros para o mesmo período.
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="finalizarDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="doCalculationStatusChange()"
          >
            Executar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isVisibleDialogPdf" persistent max-width="400">
      <v-card>
        <v-card-title>
          <v-icon x-medium class="mr-3">mdi-file-chart-outline</v-icon>
          Exportação PDF
          <v-btn @click="isVisibleDialogPdf = false" style="position: absolute; right: 5px; top: 5px" icon>
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>A exportação do relatório PDF entrou na fila para processamento e será enviado para o e-mail <b>{{ user.email }}</b> assim que finalizada.</p>
          <p>Para ver o progresso e baixar o relatório, veja a fila.</p>
        </v-card-text>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-btn color="primary" class="pr-5" dark depressed @click="isVisibleDialogPdf = false">
            <v-icon left>mdi-chevron-left</v-icon> Fechar
          </v-btn>
          <v-btn color="primary" class="px-5 ml-3" dark depressed @click="verFila()">Ver fila</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { debounce, partition, sortedUniqBy } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { BeneficiosEnum, BeneficiosLabels } from '@/core/enums/beneficios';
import { StatusCalculoEnum, StatusCalculoTranslation } from "@/core/enums/status-calculo";
import { UserTypeEnum } from '@/core/enums/user-types';

const DISPENDIOS_EXTERNOS_ITENS = [-101, -102];
const INCENTIVOS = ["reducao_irpj", "reducao_csll", "reducao_calc", "reducao_adic_irpj", "incremento", "incentivo_fiscal"];

export default {
  components: {
    "bar-chart": () => import("../../components/bar-chart.vue"),
    "line-chart": () => import("../../components/line-chart.vue"),
    "monthly-filter": () => import("../../components/monthly-filter.vue"),
    "pie-chart": () => import("../../components/pie-chart.vue"),
    "servico-select": () => import("../../components/servico-select.vue"),
    "unidade-select": () => import("../../components/unidade-select.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "getUserType", "intervaloCompetencia", "servico", "user"]),
    barChart: function () {
      return {
        chartdata: {
          labels: this.relatorioFormatadoCompetencia.map((c) =>
            this.$options.filters.toMonth(c.competencia)
          ),
          datasets: [
            {
              backgroundColor: "#6C92C5",
              borderColor: this.$chartColors[0],
              data: this.relatorioFormatadoCompetencia.map((c) => c.total),
              hidden: false,
              label: "Total Mensal",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          spanGaps: true,
          elements: { line: { tension: 0.000001 } },
          tooltips: {
            callbacks: {
              label: (tooltipItem) => {
                return this.$options.filters.toCurrency(tooltipItem.value);
              },
            },
          },
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  callback: (value) => {
                    return this.abbrNumber(value);
                  },
                },
              },
            ],
          },
          plugins: {
            filler: { propagate: false },
            "samples-filler-analyser": { target: "chart-analyser" },
          },
        },
      };
    },
    dataTableHeaders: function () {
      const dispendioCol = {
        nowrap: true,
        sortable: false,
        text: "Dispêndio",
        value: "dispendio",
        width: "200px",
      };
      const totalCol = {
        align: "end",
        format: this.$options.filters.toCurrency,
        nowrap: true,
        text: "Total",
        value: "total",
        width: "150px",
      };
      const competenciasCols = this.competenciasComDados.map(({ competencia, locked, loading }) => {
        const text = moment(competencia).format('MM/YYYY');
        return {
          align: "end",
          format: this.$options.filters.toCurrency,
          locked,
          loading,
          nowrap: true,
          sortable: false,
          text,
          value: competencia,
          width: "150px",
        }
      });

      return [ dispendioCol, ...competenciasCols, totalCol ];
    },
    exportOptions: function () {
      return {
        columns: this.dataTableHeaders,
        intervaloCompetencia: true,
        unidade: this.selectedUnit || "todas as unidades",
        styles: [1, 6, 12].reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true,
              },
            },
          }),
          {}
        ),
      };
    },
    isClient: function () {
      return ![UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(this.getUserType)
    },
    lineChart: function () {
      return {
        chartdata: {
          labels: this.relatorioFormatadoCompetencia
            .filter((r) => r.total > 0)
            .map((c) => this.$options.filters.toMonth(c.competencia)),
          datasets: this.relatorioGroupByDispendio
            .filter((r) => r.total > 0)
            .map((row, idx) => ({
              backgroundColor: "rgba(53,148,233,0)",
              borderColor: this.$chartColors[idx],
              data: Object.values(row.competencias),
              hidden: false,
              label: row.dispendio,
            })),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          spanGaps: true,
          elements: { line: { tension: 0.000001 } },
          tooltips: {
            callbacks: {
              label: (tooltipItem) => {
                return this.$options.filters.toCurrency(tooltipItem.value);
              },
            },
          },
          scales: {
            yAxes: [
              {
                stacked: false,
                ticks: {
                  callback: (value) => {
                    return this.abbrNumber(value);
                  },
                },
              },
            ],
          },
          plugins: {
            filler: { propagate: false },
            "samples-filler-analyser": { target: "chart-analyser" },
          },
        },
      };
    },
    pieChart: function () {
      const filtered = this.relatorioGroupByDispendio.filter(
        (r) => r.total > 0
      );
      const chartdata = {
        datasets: [
          {
            data: filtered.map((r) => r.total),
            backgroundColor: this.$chartColors,
            label: "Pizza",
          },
        ],
        labels: filtered.map((r) => r.dispendio),
      };
      const options = {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return this.$options.filters.toCurrency(
                data.datasets[0].data[tooltipItem.index]
              );
            },
          },
        },
      };
      return { chartdata, options };
    },
    resource: function () {
      const resumo = this.unidade ? 'resumounidade' : 'resumo';
      return this.apiResource(`/v1/rh/clientes/${this.clientId}/dispendio/${resumo}`);
    },
    relatorioGroupByDispendio: function () {
      let result = {};
      this.relatorioInterno.concat(this.relatorioExterno).forEach((row) => {
        if (!result[row.item]) {
          result[row.item] = {
            dispendio: this.opts.dispendio[row.item + ""],
            total: 0,
            competencias: {},
          };
        }
        result[row.item].competencias[row.competencia] = row.total;
        result[row.item].total += row.total;
      });
      result = Object.values(result);
      return result;
    },
    relatorioFormatadoCompetencia: function () {
      let result = {};
      this.relatorioInterno.concat(this.relatorioExterno).forEach((row) => {
        if (!result[row.competencia]) {
          result[row.competencia] = {
            competencia: row.competencia,
            total: 0,
          };
        }
        result[row.competencia][row.item] = row.total;
        result[row.competencia].total += row.total;
      });
      result = Object.values(result);
      return result;
    },
    relatorioFormatado: function () {
      const [relatorioInterno, relatorioExterno] = [this.relatorioInterno, this.relatorioExterno].map((relatorio) => {
        if (!Array.isArray(relatorio) || !relatorio.length) {
          return [];
        }

        const totalRow = {
          dispendio: this.opts.dispendio.totalRelatorio,
          order: this.opts.dispendioOrder.totalRelatorio,
          isTotal: true,
          total: 0,
        };

        return relatorio
          .reduce((acc, { competencia, item, status, total }) => {
            const found = acc.find(({ item: prevItem }) => prevItem === item);
            const parsedRow = found || {
              [competencia]: 0,
              [`${competencia}-status`]: StatusCalculoEnum.PENDENTE,
              dispendio: this.opts.dispendio[`${item}`],
              item,
              order: this.opts.dispendioOrder[`${item}`],
              total: 0,
            };

            parsedRow[competencia] = total;
            parsedRow[`${competencia}-status`] = status;
            totalRow[competencia] = total + (totalRow[competencia] || 0);
            parsedRow.total += total;
            totalRow.total += total;

            return found ? acc : [ ...acc, parsedRow ];
          }, [])
          .sort(({ order: a }, { order: b }) => a - b)
          .concat(totalRow);
      });

      // Só aparece se Lei do Bem e não deve aparecer nos gráficos
      if (this.relatorioIncentivo?.length) {
        const incentivoItems = INCENTIVOS
          .map((key) => {
            return this.relatorioIncentivo.reduce((acc, curr) => ({
              ...acc,
              [curr.competencia]: curr[key],
              // Se for incremento, faz a média das competencias exibidas e não uma soma
              total: acc.total + (key === 'incremento' ? (curr[key] / this.relatorioIncentivo.length) : curr[key]),
              format: key === 'incremento' ? (v) => `${Math.round(v)}%` : undefined,
              isTotal: key === 'incentivo_fiscal',
            }),
            {
              dispendio: this.opts.dispendio[`${key}`],
              order: this.opts.dispendioOrder[`${key}`],
              status: StatusCalculoEnum.CALCULADO,
              total: 0,
            });
          })
          .sort(({ order: a }, { order: b }) => a - b);
        relatorioInterno.push(...incentivoItems);
      }

      if (relatorioExterno.length <= 1) {
        return relatorioInterno;
      }

      const internoLastRow = relatorioInterno.pop();
      const externoLastRow = relatorioExterno.pop();
      const cols = this.dataTableHeaders.map(({ value }) => value).filter((value) => value != 'dispendio');
      const total = [internoLastRow, externoLastRow].reduce((acc, row) => {
        return cols.reduce((prev, colValue) => {
          const prevSum = prev[colValue] || 0;
          const value = row[colValue] || 0;
          return { ...prev, [colValue]: prevSum + value };
        }, acc);
      }, { dispendio: 'Total Geral', highlight: true, isTotal: true });

      internoLastRow.dispendio = 'Total Dispêndios Internos';
      externoLastRow.dispendio = 'Total Dispêndios Externos';
      relatorioInterno.push(internoLastRow);
      relatorioExterno.push(externoLastRow);

      return [...relatorioInterno, ...relatorioExterno, total];
    },
    relatorioParaExportacao: function () {
      const tableHeader = this.dataTableHeaders.map(({ text }) => text);
      const body = this.relatorioFormatado.map((item) =>
        this.dataTableHeaders.map((col) => this.getFormattedValue(col, item))
      );
      const [ini, fim] = this.intervaloCompetencia.map((comp) => this.$options.filters.toMonth(comp));
      const servico = BeneficiosLabels[this.servico];
      const unidade = this.selectedUnit || "todas as unidades";
      const relatorioHeader = [
        ["Título", "Resumo de P&D", "Período", `De ${ini} a ${fim}`],
        ["Benefício", servico, "Unidade", unidade],
        [],
      ];
      return [...relatorioHeader, tableHeader, ...body];
    },
    resourceVisualizaPeD: function () {
      return this.apiResource(
        `v1/rh/clientes/${this.clientId}/visualizaped`
      );
    },
    selectedUnit: function () {
      return this.unidade && this.unidadeList.length > 0
        ? this.unidadeList.find(({ id }) => id === this.unidade).unidade
        : null;
    },
  },
  methods: {
    changeVisibility: function ({ value }) {
      const head = this.competenciasComDados.find(({ competencia }) => competencia === value);
      head.loading = true;
      this.resourceVisualizaPeD
        .silentSave({
          competenciaIni: head.value,
          competenciaFim: head.value,
          trabalho: this.servico,
          ocultaCli: !head.locked,
        })
        .then((response) => {
          if (!response.error) {
            head.locked = !head.locked;
          }
        })
        .finally(() => {
          head.loading = false;
        });
    },
    dialogKeyDown: function (keyEvent) {
      const { code } = keyEvent;
      if (code === "Escape") {
        this.recalcularDialog = false;
        this.finalizarDialog = false;
      }
    },
    doCalculationStatusChange: function () {
      const [inicio, fim] = this.competenciaRecalculo;
      const url = `/v1/clientes/${this.clientId}/fechar/resumoPED?competenciaIni=${inicio}&competenciaFim=${fim}&trabalho=${this.servico}`;
      return this.apiResource(url).save().then(() => {
        this.finalizarDialog = false;
        return this.doLoad();
      });
    },
    getFormattedValue: function (col, item) {
      const value = item[col.value];
      const format = item.format || col.format;
      const formatedValue =
        format && col.value != "dispendio" ? format(value) : value;

      return formatedValue || "--";
    },
    getStatusMessage: function (col, item) {
      if (col.value === "dispendio") {
        return item.dispendio;
      }

      const key = `${col.value}-status`;

      if (!(key in item)) {
        return "--";
      }

      return StatusCalculoTranslation[item[key]];
    },
    getStatusTitle: function (col, item) {
      const key = `${col.value}-status`;

      if (!(key in item)) {
        return null;
      }

      return item[key] === StatusCalculoEnum.FECHADO ? 'Cálculo Fechado' : 'Cálculo Aberto';
    },
    handleExportCsv: function () {
      this.exportCsv(this.relatorioParaExportacao);
    },
    handleExportXlsx: function () {
      this.exportXlsx2(this.relatorioFormatado, this.exportOptions);
    },
    doLoad: function (filters = {}) {
      const { competencia, servico } = {
        competencia: this.intervaloCompetencia,
        servico: this.servico,
        ...filters,
      };

      if (!servico || !competencia?.length) {
        return;
      }

      const [competenciaIni, competenciaFim] = competencia;
      let query = `competenciaIni=${competenciaIni}&competenciaFim=${competenciaFim}&trabalho=${servico}`;

      if (this.unidade) {
        query += `&unidade=${this.unidade}`;
      }

      this.resource
        .get({ query })
        .then((response) => {
          if (response.error) {
            return;
          }

          if (this.servico == BeneficiosEnum.LEI_DO_BEM) {
            this.opts.dispendio = this.opts.dispendioLB;
            this.opts.dispendioOrder = this.opts.dispendioOrderLB;
            this.relatorioIncentivo = response.incentivo;
          } else {
            this.opts.dispendio = this.opts.dispendioLI;
            this.opts.dispendioOrder = this.opts.dispendioOrderLI;
            this.relatorioIncentivo = [];
          }

          const competencias = response.calc.map(({ competencia, ocultaCli }) => ({ competencia, locked: !!ocultaCli, loading: false })).sort(({ competencia: a }, { competencia: b }) => a - b);
          this.competenciasComDados = sortedUniqBy(competencias, 'competencia');

          // Unifica os itens -1 (Materiais de Consumo) e -2 (não aparece nesse relatório)
          this.competenciasComDados.forEach(({ competencia }) => {
            const filtered = response.calc.filter((item) => item.competencia == competencia);

            if (filtered.length > 0) {
              const item1 = filtered.find(({ item }) => item === -1);
              const item2 = filtered.find(({ item }) => item === -2);

              if (!item1 && item2) {
                item2.item = -1;
              } else if (item1 && item2) {
                item1.total += item2.total;
                const index = response.calc.indexOf(item2);
                response.calc.splice(index, 1);
              }
            }
          });

          const expectedItems = response.calc?.filter((row) => this.opts.dispendio[`${row.item}`]) || [];
          [this.relatorioInterno, this.relatorioExterno] = partition(expectedItems, ({ item }) => !DISPENDIOS_EXTERNOS_ITENS.includes(item));
        });
    },
    mostrarIcone: function (head) {
      return !["dispendio", "total"].includes(head.value);
    },
    recalcularPeriodo: function () {
      const [ini, fim] = this.competenciaRecalculo;
      const query = `competenciaIni=${ini}&competenciaFim=${fim}&trabalho=${this.servico}`;
      this.apiResource(
        `v1/rh/clientes/${this.clientId}/dispendio/recalcresumo?${query}`
      )
        .save()
        .then(() => {
          this.recalcularDialog = false;
          return this.doLoad();
        });
    },
    showStatus: function (col, item) {
      const { value } = col;
      if (value === 'dispendio') {
        return false;
      }

      const key = `${value}-status`;
      return [
        StatusCalculoEnum.AGUARDANDO_CALCULO,
        StatusCalculoEnum.EM_PROCESSAMENTO,
        StatusCalculoEnum.PENDENTE,
      ].includes(item[key]);
    },
    showLock: function (col, item) {
      const { value } = col;
      if (value === 'dispendio') {
        return false;
      }

      const key = `${value}-status`;
      return (
        key in item &&
        [
          StatusCalculoEnum.CALCULADO,
          StatusCalculoEnum.FECHADO
        ].includes(item[key])
      );
    },
    isLocked: function (col, item) {
      const { value } = col;
      if (value === 'dispendio') {
        return false;
      }

      const key = `${value}-status`;
      return key in item && item[key] === StatusCalculoEnum.FECHADO;
    },
    handleExportPdf: async function () {
      const [ini, fim] = this.intervaloCompetencia;
      const url = `v1/clientes/${this.clientId}/exportacao`;
      const opts = {
        "competenciaIni": ini,
        "competenciaFim": fim,
        "trabalho": this.servico,
        "unidade": this.unidade || null,
      }
      const response = await this.exportPdf(url, "resumo-de-ped", opts);
      if (!response.error) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "success",
          title: "Relatório PDF",
          text: "O relatorio foi enviado para seu e-mail e está disponível em Relatórios Exportados",
        });
        this.isVisibleDialogPdf = true;
      }
    },
    verFila: function () {
      this.$router.push({
        path: "/clientes/dashboard/relatorios-exportados",
      });
    },
  },
  data: function () {
    return {
      competenciaRecalculo: this.$store.getters.intervaloCompetencia,
      competenciasComDados: [],
      dateMenu1: false,
      dateMenu2: false,
      debounceDoLoad: debounce(this.doLoad, 100),
      finalizarDialog: false,
      recalcularDialog: false,
      relatorioExterno: [],
      relatorioInterno: [],
      unidade: null,
      unidadeList: [],
      opts: {
        dispendio: {},
        dispendioOrder: {},
        dispendioLI: {
          1: "RH DIRETO",
          2: "RH INDIRETO",
          "-4": "EQUIPAMENTOS E SOFTWARE",
          "-5": "OBRAS CIVIS",
          "-6": "MATERIAL DE CONSUMO",
          "-7": "SERVIÇOS TÉCNICOS",
          "-8": "TREINAMENTO",
          "-9": "LIVROS E PERIÓDICOS",
          "-10": "VIAGENS",
          "-11": "OUTROS CORRELATOS",
          "-12": "AUDITORIA INDEPENDENTE",
          "-13": "MATERIAL DE PROTÓTIPO",
          "-101": "Boleto",
          "-102": "Convênio",
          totalRelatorio: "TOTAL GERAL",
        },
        dispendioOrderLI: {
          1: 1,
          2: 2,
          "-4": 3,
          "-5": 4,
          "-6": 5,
          "-7": 7,
          "-8": 8,
          "-9": 9,
          "-10": 10,
          "-11": 11,
          "-12": 12,
          "-13": 6,
          "-101": 13,
          "-102": 14,
          totalRelatorio: 15,
        },
        dispendioLB: {
          1: "RH - Dedicação Exclusiva",
          2: "RH - Dedicação Parcial",
          3: "Apoio Técnico",
          "-3": "Serviços de Terceiros e Viagens",
          "-1": "Materiais de Consumo",
          reducao_irpj: "Redução IRPJ",
          reducao_csll: "Redução CSLL",
          reducao_calc: "Exclusão Aproveitável",
          reducao_adic_irpj: "Redução Adicional IRPJ",
          incremento: "Incremento",
          totalRelatorio: "Total Geral",
          incentivo_fiscal: "Incentivo Fiscal (IRPJ e CSLL)",
        },
        dispendioOrderLB: {
          1: 1,
          2: 2,
          3: 3,
          "-3": 4,
          "-1": 5,
          totalRelatorio: 6,
          incremento: 6.5,
          reducao_calc: 7,
          reducao_irpj: 8,
          reducao_adic_irpj: 9,
          reducao_csll: 10,
          incentivo_fiscal: 11,
        },
      },
      isVisibleDialogPdf: false,
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep .v-data-table__wrapper {
  position: relative;

  thead tr {
    &, & th {
      background-color: var(--v-table-header-base);
    }
  }

  th:first-child {
    z-index: 3;
  }

  td:first-child {
    background-color: #ffffff;
    z-index: 1;
  }

  .blue.lighten-5 td {
    background-color: #e3f2fd;
  }

  .blue.lighten-5.highlight td {
    background-color: #bedefc;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
  }
}

.loading-icon-wrapper {
  display: inline-block;
  padding: 0 10px;
  height: 21px;
}

.animated-loading-icon {
  animation: 1s infinite linear rotationchange;
}

@keyframes rotationchange {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
